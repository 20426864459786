<template>
  <CRow>
    <CCol col="12" xl="12">
      <transition name="slide">
        <ACRUDTable
          :fields="[
            //'id',
            'account_number',
            'name',
            getHelperObject('account_type'),
            getHelperObject('equivalence_application')
            //{ ...helpers.validIcons, key: 'is_principal' }, // TODO: check if needed ?
          ]"
          :config="{
            url: 'admin/pucs',
            route: '/admin/property/pucs',
            name: 'puc'
          }"
          @preparedHelpers="onPreparedHelpers"
        />
      </transition>
    </CCol>
  </CRow>
</template>

<script>
import crudMixin from '@/app/views/_mixins/crud-mixin'

export default {
  name: 'PUCs',
  mixins: [crudMixin]
}
</script>
