var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","xl":"12"}},[_c('transition',{attrs:{"name":"slide"}},[_c('ACRUDTable',{attrs:{"fields":[
          //'id',
          'account_number',
          'name',
          _vm.getHelperObject('account_type'),
          _vm.getHelperObject('equivalence_application') ],"config":{
          url: 'admin/pucs',
          route: '/admin/property/pucs',
          name: 'puc'
        }},on:{"preparedHelpers":_vm.onPreparedHelpers}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }